const data = [
  {
    name: "Instagram",
    icon: "mdi-instagram",
    link: "https://www.instagram.com/dycerentals"
  },
  {
    name: "Twitter",
    icon: "mdi-twitter",
    link: "https://www.twitter.com/dycerentals"
  },
  {
    name: "Facebook",
    icon: "mdi-facebook",
    link: "https://www.facebook.com/people/Dyce-Rentals/61557322134299/"
  },
]

  export default data;